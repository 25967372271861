
export const persistedKeys = ["email", "createdAt","organisation", "organisationRole", "password", 
  "surname", "uid", "id", "webRole","name","image","likes","dislikes","connectedDevices","deadline","myNews","myEcg","myRealCases",
  "myDevelopment","myCardio3d","myInteractiveImage","myPatient","mySession","myActiveSession","myCartoCases","myDicom","marketingCookie", "phone", "smsVerification"];

export interface UserState {
  createdAt: string,
  email: string,
  id: string,
  name: string,
  organisation: string,
  organisationRole: string,
  password: string,
  surname: string,
  uid: string,
  webRole: string,
  loading: boolean,
  image: string,
  myNews: Array<any>,
  myEcg: Array<any>,
  myRealCases: Array<any>,
  myDevelopment: Array<any>,
  myCardio3d: Array<any>,
  myInteractiveImage: Array<any>,
  myPatient: Array<any>,
  mySession: Array<any>,
  myActiveSession: Array<any>,
  myCartoCases: Array<any>,
  myDicom: Array<any>,
  likes: Array<any>,
  dislikes: Array<any>,
  connectedDevices: Array<any>,
  deadline: string,
  marketingCookie: any,
  phone: string,
  smsVerification: boolean
}

export const initialState: UserState = {
  loading: false,
  createdAt: "",
  email: "",
  id: "",
  name: "",
  organisation: "",
  organisationRole: "",
  password: "",
  surname: "",
  uid: "",
  webRole: "",
  image: "",
  myNews: [],
  myRealCases: [],
  myDevelopment: [],
  myCardio3d: [],
  myInteractiveImage: [],
  myEcg: [],
  myPatient: [],
  mySession: [],
  myActiveSession: [],
  myCartoCases: [],
  myDicom: [],
  likes: [],
  dislikes: [],
  connectedDevices: [],
  deadline: "",
  marketingCookie: undefined,
  phone: "",
  smsVerification: false
};
